import type { PaginateResult, QueryOptions } from "mongoose"
import type { InspectionProperty } from "./inspection.store"
import type { Asset, AssetValue } from "./asset.store"
import type { DomainType } from "./organization.store"

export interface Solution {
  _id: string
  description: string
  organization?: string
}

export interface Reason {
  _id: string
  description: string
  solutions: Array<Solution>
  organization?: string
}

export interface ComponentType {
  _id: string
  description: string
  reasons: Array<Reason>
  domain: DomainType
}

export interface ComponentGroup {
  _id: string
  organization?: string
  description: string
  key?: string
  components: Array<string>
  inspectionProperties: Array<InspectionProperty>
  archived?: boolean
  domain: DomainType
}

export interface Component {
  _id: string
  organization?: string
  componentType: ComponentType["description"]
  description: string
  key?: string
  showInBudget: boolean
  hasGeneralCondition: boolean
  /** Default interval for this component, can be overridden by asset category */
  interval?: number
  properties: Array<PopulatedComponentProperty>
  inspectionProperties: Array<InspectionProperty>
  archived?: boolean
  domain: DomainType
}

export enum PropertyValueType {
  BUILD_YEAR = "BUILD_YEAR",
  RENOVATION_YEAR = "RENOVATION_YEAR",
  INSTALLATION_YEAR = "INSTALLATION_YEAR",
  TEXT = "TEXT",
  LOOKUP = "LOOKUP",
  NUMBER = "NUMBER",
  CHECKBOX = "CHECKBOX",
}

export interface PopulatedComponentProperty extends Omit<ComponentProperty, "lookupType"> {
  lookupType?: { _id: string; description: string; parent?: string }
  /** Not part of model, but is aggregated here when getting componentGroups of an asset */
  value?: AssetValue["value"]
  lookupValue?: string
}

export interface ComponentProperty {
  _id: string
  lookupType?: string
  sortOrder: number
  description: string
  valueType: PropertyValueType
  valueRequired: boolean
  valuePrefix?: string
  valueSuffix?: string
  optional: boolean
  showInCaption?: boolean
  archived?: boolean
  remark?: string
  organization?: string
}

export interface ComponentPropertyOptional {
  isOptional: boolean
  shouldTransfer?: boolean
}

export const GENERAL_COMPONENT_TYPE_DESCRIPTION = "GENERAL"

export const useComponentStore = defineStore("component", () => {
  /** @deprecated */
  const groups = ref<Array<ComponentGroup>>([])
  /** @deprecated */
  const components = ref<Array<Component>>([])
  /** @deprecated */
  const types = ref<Array<ComponentType>>([])

  const getGroups = async (options?: QueryOptions) =>
    useCrudMethods<ComponentGroup>("/component-groups", groups).readItems({ pagination: false, ...options })

  const getGroup = async (id: string) => useCrudMethods<ComponentGroup>("/component-groups", groups).readItemById(id)

  const updateGroup = async (id: string, data: Partial<ComponentGroup>) =>
    await useCrudMethods<ComponentGroup>("/component-groups", groups).updateItem(id, { ...data })

  const createGroup = async (data: Partial<ComponentGroup>) =>
    await useCrudMethods<ComponentGroup>("/component-groups", groups).createItem({ ...data })

  const deleteGroup = async (id: string) => await useCrudMethods<ComponentGroup>("/component-groups", groups).deleteItem(id)

  const getComponents = async (componentGroupId?: string, options?: QueryOptions): Promise<Array<Component>> => {
    const queryParameters: QueryOptions & { query?: Record<string, unknown> | string } = { pagination: false, ...options }

    if (componentGroupId) {
      const group = componentGroupId ? await getGroup(componentGroupId) : undefined
      const componentIds = group?.components ?? []
      queryParameters.query = JSON.stringify({ ...(queryParameters.query as Record<string, unknown>), _id: { $in: componentIds } })
    }

    return await useCrudMethods("/components", components).readItems(queryParameters)
  }

  const getComponent = async (id: string) => useCrudMethods<Component>("/components", components).readItemById(id)

  const updateComponent = async (id: string, data: Partial<Component>) =>
    await useCrudMethods<Component>("/components", components).updateItem(id, { ...data })

  const createComponent = async (data: Partial<Component>) =>
    await useCrudMethods<Component>("/components", components).createItem({ ...data })

  const deleteComponent = async (id: string) => await useCrudMethods<Component>("/components", components).deleteItem(id)

  const updatePropertyOptional = async (propertyId: string, componentId: string, data: ComponentPropertyOptional) => {
    const { data: updatedPropertiesCount } = await useSamApi<Component>(`/components/${componentId}/properties/${propertyId}/optional`, {
      method: "PUT",
      body: JSON.stringify({ isOptional: data.isOptional, shouldTransfer: data.shouldTransfer }),
    })
    return updatedPropertiesCount
  }

  const updateProperty = async (propertyId: string, componentId: string, data: Partial<ComponentProperty>) => {
    const { data: updatedComponent } = await useSamApi<Component>(`/components/${componentId}/properties/${propertyId}`, {
      method: "PUT",
      body: JSON.stringify(data),
    })
    return updatedComponent
  }

  const createProperty = async (componentId: string, data: Partial<ComponentProperty>) => {
    const { data: updatedComponent } = await useSamApi<Component>(`/components/${componentId}/properties`, {
      method: "POST",
      body: JSON.stringify(data),
    })
    return updatedComponent
  }

  const canDeleteProperty = async (propertyId: string, componentId: string) => {
    const { data: canDelete } = await useSamApi<boolean>(`/components/${componentId}/properties/${propertyId}/can-delete`)
    return canDelete
  }

  const deleteProperty = async (propertyId: string, componentId: string) => {
    const { data: updatedComponent } = await useSamApi<Component>(`/components/${componentId}/properties/${propertyId}`, {
      method: "DELETE",
    })
    return updatedComponent
  }

  const updateComponentInspectionProperty = async (propertyId: string, componentId: string, data: Partial<InspectionProperty>) => {
    const { data: updatedComponent } = await useSamApi<Component>(`/components/${componentId}/inspection-properties/${propertyId}`, {
      method: "PUT",
      body: JSON.stringify(data),
    })
    return updatedComponent
  }

  const createComponentInspectionProperty = async (componentId: string, data: Partial<InspectionProperty>) => {
    const { data: updatedComponent } = await useSamApi<Component>(`/components/${componentId}/inspection-properties`, {
      method: "POST",
      body: JSON.stringify(data),
    })
    return updatedComponent
  }

  const deleteComponentInspectionProperty = async (propertyId: string, componentId: string) => {
    const { data: updatedComponent } = await useSamApi<Component>(`/components/${componentId}/inspection-properties/${propertyId}`, {
      method: "DELETE",
    })
    return updatedComponent
  }

  const updateComponentGroupInspectionProperty = async (
    propertyId: string,
    componentGroupId: string,
    data: Partial<InspectionProperty>,
  ) => {
    const { data: updatedComponentGroup } = await useSamApi<ComponentGroup>(
      `/component-groups/${componentGroupId}/inspection-properties/${propertyId}`,
      { method: "PUT", body: JSON.stringify(data) },
    )
    return updatedComponentGroup
  }

  const createComponentGroupInspectionProperty = async (componentGroupId: string, data: Partial<InspectionProperty>) => {
    const { data: updatedComponentGroup } = await useSamApi<ComponentGroup>(`/component-groups/${componentGroupId}/inspection-properties`, {
      method: "POST",
      body: JSON.stringify(data),
    })
    return updatedComponentGroup
  }

  const deleteComponentGroupInspectionProperty = async (propertyId: string, componentGroupId: string) => {
    const { data: updatedComponentGroup } = await useSamApi<ComponentGroup>(
      `/component-groups/${componentGroupId}/inspection-properties/${propertyId}`,
      { method: "DELETE" },
    )
    return updatedComponentGroup
  }

  const getComponentTypes = async (options?: QueryOptions) =>
    useCrudMethods<ComponentType>("/component-types", types).readItems({ pagination: false, ...options })

  const getComponentTypesByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<ComponentType>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<ComponentType>>(`/component-types?${query}`)
    return data
  }

  const getComponentTypeById = async (id: string): Promise<ComponentType> => {
    const { data } = await useSamApi<ComponentType>(`/component-types/${id}`)
    return data
  }

  const createComponentType = async (data: Partial<ComponentType>): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(`/component-types`, {
      method: "POST",
      body: JSON.stringify(data),
    })
    return updatedComponentType
  }

  const updateComponentType = async (id: string, data: Partial<ComponentType>): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(`/component-types/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
    })
    return updatedComponentType
  }

  const deleteComponentType = async (id: string): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(`/component-types/${id}`, { method: "DELETE" })
    return updatedComponentType
  }

  const addComponentTypeReason = async (componentTypeId: string, description: string): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(`/component-types/${componentTypeId}/reasons`, {
      method: "POST",
      body: JSON.stringify({ description }),
    })
    return updatedComponentType
  }

  const renameComponentTypeReason = async (componentTypeId: string, reasonId: string, description: string): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(
      `/component-types/${componentTypeId}/reasons/${reasonId}/description`,
      { method: "PUT", body: JSON.stringify({ description }) },
    )
    return updatedComponentType
  }

  const setComponentTypeReasonOrganization = async (
    componentTypeId: string,
    reasonId: string,
    organizationId?: string,
  ): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(
      `/component-types/${componentTypeId}/reasons/${reasonId}/organization`,
      { method: "PUT", body: JSON.stringify({ organization: organizationId }) },
    )
    return updatedComponentType
  }

  const deleteComponentTypeReason = async (componentTypeId: string, reasonId: string): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(`/component-types/${componentTypeId}/reasons/${reasonId}`, {
      method: "DELETE",
    })
    return updatedComponentType
  }

  const addComponentTypeSolution = async (componentTypeId: string, reasonId: string, description: string): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(
      `/component-types/${componentTypeId}/reasons/${reasonId}/solutions`,
      { method: "POST", body: JSON.stringify({ description }) },
    )
    return updatedComponentType
  }

  const renameComponentTypeSolution = async (
    componentTypeId: string,
    reasonId: string,
    solutionId: string,
    description: string,
  ): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(
      `/component-types/${componentTypeId}/reasons/${reasonId}/solutions/${solutionId}/description`,
      { method: "PUT", body: JSON.stringify({ description }) },
    )
    return updatedComponentType
  }

  const setComponentTypeSolutionOrganization = async (
    componentTypeId: string,
    reasonId: string,
    solutionId: string,
    organizationId?: string,
  ): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(
      `/component-types/${componentTypeId}/reasons/${reasonId}/solutions/${solutionId}/organization`,
      { method: "PUT", body: JSON.stringify({ organization: organizationId }) },
    )
    return updatedComponentType
  }

  const deleteComponentTypeSolution = async (componentTypeId: string, reasonId: string, solutionId: string): Promise<ComponentType> => {
    const { data: updatedComponentType } = await useSamApi<ComponentType>(
      `/component-types/${componentTypeId}/reasons/${reasonId}/solutions/${solutionId}`,
      { method: "DELETE" },
    )
    return updatedComponentType
  }

  const componentsCountByComponentType = async (componentTypeId: string): Promise<number> => {
    const { data } = await useSamApi<{ count: number }>(`/component-types/${componentTypeId}/component-count`)
    return data.count
  }

  const getComponentGroupAssetList = async (componentGroupId: string): Promise<Array<Asset>> => {
    const { data } = await useSamApi<Array<Asset>>(`/component-groups/${componentGroupId}/assets`)
    return data
  }

  const getComponentAssetList = async (componentId: string): Promise<Array<Asset>> => {
    const { data } = await useSamApi<Array<Asset>>(`/components/${componentId}/assets`)
    return data
  }

  const getComponentGroupTemplateList = async (componentGroupId: string): Promise<Array<Template>> => {
    const { data } = await useSamApi<Array<Template>>(`/component-groups/${componentGroupId}/templates`)
    return data
  }

  const getComponentTemplateList = async (componentId: string): Promise<Array<Template>> => {
    const { data } = await useSamApi<Array<Template>>(`/components/${componentId}/templates`)
    return data
  }

  const getComponentComponentGroupList = async (componentId: string): Promise<Array<ComponentGroup>> => {
    const { data } = await useSamApi<Array<ComponentGroup>>(`/components/${componentId}/groups`)
    return data
  }

  const deleteBrlInfoFromComponentInspectionProperty = async (inspectionPropertyId: string, componentId: string): Promise<Component> => {
    const { data: updatedComponent } = await useSamApi<Component>(
      `/components/${componentId}/inspection-properties/${inspectionPropertyId}/brl-info`,
      { method: "DELETE" },
    )
    return updatedComponent
  }

  const deleteBrlInfoFromComponentGroupInspectionProperty = async (
    inspectionPropertyId: string,
    componentGroupId: string,
  ): Promise<ComponentGroup> => {
    const { data: updatedComponentGroup } = await useSamApi<ComponentGroup>(
      `/component-groups/${componentGroupId}/inspection-properties/${inspectionPropertyId}/brl-info`,
      { method: "DELETE" },
    )
    return updatedComponentGroup
  }

  const getComponentArchived = async (componentId: string): Promise<boolean> => {
    const { data: archived } = await useSamApi<boolean>(`/components/${componentId}/archived`)
    return archived
  }

  return {
    groups,
    components,

    getGroups,
    getGroup,
    updateGroup,
    createGroup,
    deleteGroup,

    getComponents,
    getComponent,
    updateComponent,
    createComponent,
    deleteComponent,

    updateProperty,
    updatePropertyOptional,
    createProperty,
    canDeleteProperty,
    deleteProperty,

    updateComponentInspectionProperty,
    createComponentInspectionProperty,
    deleteComponentInspectionProperty,

    updateComponentGroupInspectionProperty,
    createComponentGroupInspectionProperty,
    deleteComponentGroupInspectionProperty,

    getComponentTypes,
    getComponentTypesByPage,
    getComponentTypeById,
    createComponentType,
    updateComponentType,
    deleteComponentType,

    addComponentTypeReason,
    renameComponentTypeReason,
    setComponentTypeReasonOrganization,
    deleteComponentTypeReason,

    addComponentTypeSolution,
    renameComponentTypeSolution,
    setComponentTypeSolutionOrganization,
    deleteComponentTypeSolution,

    componentsCountByComponentType,

    getComponentAssetList,
    getComponentGroupAssetList,
    getComponentGroupTemplateList,
    getComponentTemplateList,
    getComponentComponentGroupList,

    deleteBrlInfoFromComponentInspectionProperty,
    deleteBrlInfoFromComponentGroupInspectionProperty,

    getComponentArchived,
  }
})
